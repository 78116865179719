import React, { useState, useEffect } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import {
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  useNotify,
  Button,
  required,
} from 'react-admin';

import { DescriptionGen } from './DescriptionGen';

const useStyles = makeStyles({
  fileInput: {
    margin: '20px 0',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#f0f0f0',
  },
  fileField: {
    display: 'grid',
    alignItems: 'center',
    padding: '5px',
    color: '#1976d2',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

export const PinGeneration = () => {
  const [useCase, setUseCase] = useState('');
  const [verifierId, setVerifierId] = useState('');
  const [crefoTrustKey, setCrefoTrustKey] = useState('');
  const [verifierSearchName, setverifierSearchName] = useState('');
  const [verifierName, setVerifierName] = useState('');
  const [verifierOptions, setVerifierOptions] = useState(null);
  const [options, setOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [useCaseLoading, setUseCaseLoading] = useState(false);
  const [isVerifierLoading, setIsVerifierLoading] = useState(false);
  const notify = useNotify();
  const classes = useStyles();

  const handleUpload = async () => {
    if (!file) {
      notify('File is required', { type: 'error' });
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('crefoTrustKey', crefoTrustKey);
    formData.append('verifierId', verifierId);
    formData.append('useCaseName', useCase);
    await fetch(`${window._env_.API_URL}/pins`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'x-crefotrust-api-key': crefoTrustKey,
      },
    })
      .then(async (res) => {
        FileSaver.saveAs(await res.blob(), file.name);
      })
      .catch(() => {
        notify('No Pins generated found.', { type: 'error' });
      });
  };

  const handleVerifierChange = async () => {
    try {
      setIsVerifierLoading(true);
      await fetch(
        `${window._env_.API_URL}/verifiers/${verifierSearchName.target.value}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        }
      )
        .then(async (res) => {
          const json = await res.json();
          if (json.length === 1) {
            setVerifierName(json[0].name);
          } else if (json.length === 0) {
            setVerifierName('');
          }

          setVerifierOptions(
            json.map((option) => ({
              id: option.name,
              name: option.name,
            }))
          );
        })
        .catch(() => {
          notify('No verifier found.', { type: 'warning' });
        })
        .finally(() => {
          setIsVerifierLoading(false);
        });
    } catch (error) {
      notify('No verifier found.', { type: 'warning' });
    }
  };

  const resetAll = () => {
    setUseCase('');
    setVerifierId('');
    setOptions([]);
    setverifierSearchName('');
    setVerifierName('');
    setVerifierOptions(null);
    setCrefoTrustKey('');
  };

  useEffect(() => {
    const fetchOptions = async () => {
      if (verifierName) {
        setUseCaseLoading(true);
        await fetch(`${window._env_.API_URL}/verifiers/${verifierName}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        })
          .then(async (res) => {
            res.json().then((json) => {
              setVerifierId(json[0].verifierId);
              setCrefoTrustKey(json[0].apiKey);
            });
          })
          .finally(() => {
            setUseCaseLoading(false);
          });
      }
    };

    fetchOptions();
  }, [verifierName]);

  useEffect(() => {
    const fetchUseCases = async () => {
      if (verifierId && crefoTrustKey) {
        await fetch(`${window._env_.API_URL}/verifiers/useCase/${verifierId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'x-crefotrust-api-key': crefoTrustKey,
          },
        })
          .then(async (res) => {
            const response = await res.json();

            if (!response || response.useCases.length === 0) {
              notify('No use case found.', { type: 'warning' });
            } else if (response.useCases.length === 1) {
              const firstUsecase = response.useCases[0];
              setUseCase(firstUsecase.name);
              setOptions([{ name: firstUsecase.name }]);
            } else {
              const optionsList = response.useCases.map((useCaseX) => ({
                name: useCaseX.name,
              }));
              setOptions(optionsList);
            }
          })
          .catch(() => {
            notify('No use case found.', { type: 'warning' });
          });
      }
    };

    fetchUseCases();
  }, [crefoTrustKey, verifierId, notify]);

  useEffect(() => {
    if (verifierOptions?.length === 1) {
      const singleVerifierName = verifierOptions[0]?.name;
      setVerifierName(singleVerifierName);
    }
  }, [verifierOptions]);

  return (
    <SimpleForm save={handleUpload} filter={[]}>
      <FileInput
        source='file'
        label='File Upload'
        accept='.csv'
        onChange={(e) => setFile(e)}
        placeholder={<p>Drop your CSV file here</p>}
        className={classes.fileInput}
        validate={required()}>
        <FileField source='src' title='title' className={classes.fileField} />
      </FileInput>
      <TextInput
        label='Finde einen Verifier'
        source='verifierName'
        onChange={(e) => setverifierSearchName(e)}
        // onChange={debouncedChangeHandler}
        variant='outlined'
        validate={required()}
        value={verifierSearchName}
        helperText=''
        sx={{
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
          '& .MuiFormHelperText-contained': {
            display: 'none',
          },
          '& .MuiFormHelperText-marginDense': {
            display: 'none',
          },
        }}
      />
      <Button
        label='Verifier finden'
        onClick={handleVerifierChange}
        className={classes.fileInput}
        disabled={!verifierSearchName || isVerifierLoading}
      />
      {verifierOptions && (
        <Box sx={{ marginBottom: '30px' }}>
          <FormControl style={{ marginTop: '1em', width: '100%' }}>
            <InputLabel id='suggestions-select-label'>
              Wähle einen der Verifier aus
            </InputLabel>
            <Select
              labelId='suggestions-select-label'
              value={verifierName}
              onChange={(event) => setVerifierName(event.target.value)}>
              {verifierOptions.map((verifier) => (
                <MenuItem key={verifier.id} value={verifier.id}>
                  {verifier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {isVerifierLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      {verifierOptions && verifierId && useCase && (
        <Box sx={{ marginBottom: '30px' }}>
          <FormControl style={{ marginTop: '1em', width: '100%' }}>
            <InputLabel id='options-select-label'>
              Suche einen Use case aus
            </InputLabel>
            <Select
              labelId='options-select-label'
              value={useCase}
              onChange={(event) => setUseCase(event.target.value)}>
              {options.map((option, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {verifierOptions && useCaseLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      )}
      <DescriptionGen />
      <Button label='Reset' onClick={resetAll} className={classes.fileInput} />
    </SimpleForm>
  );
};
